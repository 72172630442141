import { createSlice, current } from '@reduxjs/toolkit';
import { FormStates, SaveStates } from '../../../constants/formStates';
import _ from 'lodash';



const initialState = {
  updateRecord: null,
  lastSavedRecord: null,
  saveResponseAgreement: null,
  formState: FormStates.unChanged,
  globalLoading: false,
  agreementList: null,
  selectedAgreement: null,
  selectedAgreementLoading: false,
  selectedAgreementId: null,
  selectedAgreementPartsLoading: false,
  selectedAgreementPartDetailLoading: false,
  selectedAgreementPartIndex: -1,
  selectedAgreementServicesLoading: false,
  selectedAgreementServicesCount: 0,
  selectedAgreementServiceIndex: -1,
  selectedAgreementServicesDetailsLoading: false,
  selectedAgreementPeriodIndex: -1,
  selectedAgreementPeriodsCount: 0,
  selectedAgreementPriceIndex: -1,
  selectedAgreementPricesCount: 0,
  newAgreement: false,
  newAgreementPart: false,
  newAgreementPartDetails: null,
  newAgreementService: false,
  newAgreementServiceDetails: null,
  editedAgreementService: null,
  updateForAgreementService: null,  
  newAgreementPeriod: false,
  newAgreementPeriodDetails: null,
  editedAgreementPeriod: null,
  periodListForDelete: [],
  updateForAgreementPeriod: null,
  newAgreementPrice: false,
  newAgreementPriceDetails: null,
};

function arrangeUpdateRecord(state, position, record , part= null , service=null , period= null , price= null) {
  // Agreement Main
  if (!state.updateRecord) state.updateRecord = { id: state.selectedAgreement.id };
  if (position === 'agreement') {
    state.updateRecord = { ...state.updateRecord, ...record };
    return;
  }
  //Agreement Parts --
  if (!state.updateRecord.AgreementParts) {
    state.updateRecord = {
      ...state.updateRecord,
      ...{ AgreementParts: [] },
    };
  }
  if (position === 'new_part') {
    state.updateRecord.AgreementParts.push(record);
    return;
  }
  let partIndex = state.updateRecord.AgreementParts.findIndex(x => x.id === part.id);
  if(partIndex < 0) {
    state.updateRecord.AgreementParts.push({id : part.id});
    partIndex = state.updateRecord.AgreementParts.findIndex(x => x.id === part.id);
    console.assert(partIndex >= 0, "Part is not found");
  }
  if (position === 'part_update') {
    state.updateRecord.AgreementParts[partIndex] = {
      ...state.updateRecord.AgreementParts[partIndex],
      ...record,
    };
    return;
  }
  //Agreement Service
  if (!state.updateRecord.AgreementParts[partIndex].AgreementServices) {
    state.updateRecord.AgreementParts[partIndex] = {
      ...state.updateRecord.AgreementParts[partIndex],
      ...{ AgreementServices: [] },
    };
  }
  if (position === 'new_service') {
    state.updateRecord.AgreementParts[partIndex].AgreementServices.push(record);  
    return;
  }
  let serviceIndex = state.updateRecord.AgreementParts[partIndex].AgreementServices.findIndex(x => x.id === service.id);
  if(serviceIndex < 0) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices.push({id: service.id});
    serviceIndex =  state.updateRecord.AgreementParts[partIndex].AgreementServices.findIndex(x => x.id === service.id);
    console.assert(serviceIndex >= 0, "Service is not found");
  }
  if (position === 'service_update') {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex] = {
      ...state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex],
      ...record,
    };
    return;
  }
  //Agreement Service Period
  if (
    !state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods
  ) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex] = {
      ...state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex],
      ...{ agreementPeriods: [] },
    };
  }
  if (position === 'new_period') {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[
      serviceIndex
    ].agreementPeriods.push(record);
    return;
  }
  
  let periodIndex = state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods.findIndex(x => x.id === period.id);
  if(periodIndex < 0) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods.push({id: period.id});
    periodIndex = state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods.findIndex(x => x.id === period.id);
    console.assert(periodIndex >= 0, "Period is not found");
  }
  if (position === 'period_update' ) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods[
      periodIndex
    ] = {
      ...state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex]
        .agreementPeriods[periodIndex],
      ...record,
    };
    return;
  }
  // Agreement Service Price
  if (
    !state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex]
      .agreementPeriods[periodIndex].pricingConditions
  ) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods[
      periodIndex
    ] = {
      ...state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex]
        .agreementPeriods[periodIndex],
      ...{ pricingConditions: [] },
    };
  }
  if (position === 'new_price') {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods[
      periodIndex
    ].pricingConditions.push(record);
    return;
  }
  let priceIndex = state.updateRecord.AgreementParts[partIndex]
                        .AgreementServices[serviceIndex]
                        .agreementPeriods[periodIndex]
                        .pricingConditions.findIndex(x => x.id === price.id);
  if(priceIndex < 0) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods[periodIndex]?.pricingConditions.push({id:price?.id});
    priceIndex = state.updateRecord.AgreementParts[partIndex]
                      .AgreementServices[serviceIndex].agreementPeriods[periodIndex]
                      ?.pricingConditions.findIndex(x => x.id === price?.id);
    console.assert(priceIndex >= 0, "Price is not found");
  }
  if (position === 'price_update' && priceIndex >= 0) {
    state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex].agreementPeriods[
      periodIndex
    ].pricingConditions[priceIndex] = {
      ...state.updateRecord.AgreementParts[partIndex].AgreementServices[serviceIndex]
        .agreementPeriods[periodIndex].pricingConditions[priceIndex],
      ...record,
    };
    return;
  }
}

export const selectedAgreementSlice = createSlice({
  name: 'CAselectedAgreement',
  initialState,
  reducers: {
    createNewAgreement: (state, action) => {
      const { uniqueId, agreement } = action.payload || {
        uniqueId: null,
        agreement: null,
      };
      console.log('createNewAgreement', uniqueId, ' ', state.newAgreement);
      if (state.newAgreement === false) {
        //Create a new record just user not start add operation before otherwise continue the same add op.
        if (agreement) {
          //copy
          let newAgreement = {
            ...agreement,
            id: 'new_' + uniqueId,
            description:
              "Coppied One, Don't forget to save after you finish editing",
          };
          giveIdsAgreementParts({
            agreement: newAgreement,
            uniqueId: uniqueId,
          });
          state.selectedAgreement = newAgreement;
          state.selectedAgreementId = newAgreement.id;
          arrangeUpdateRecord(state, 'agreement', newAgreement);
          // state.agreementList.push(newAgreement);
        } else {
          // add
          state.selectedAgreement = {
            id: 'new_' + uniqueId,
            AgreementParts: [],
          };
          state.selectedAgreementId = 'new_' + uniqueId;
          state.newAgreementPartDetails = null;
          //    state.selectedAgreementServices = null;
          state.selectedAgreementServicesCount = null;
          state.selectedAgreementPeriod = null;
          //state.selectedAgreementPeriodDetails = null;
          //  state.selectedAgreementPeriodsCount = null;
          state.selectedAgreementPrice = null;
          state.selectedAgreementPricesCount = null;
          state.updateRecord = {}; // no id on insertion
        }
        state.agreementList = null;
        state.formState = FormStates.modified;
        state.newAgreement = true;
      }
    },
    setAgreementList: (state, action) => {
      state.agreementList = action.payload;
    },
    setSelectedAgreement: (state, action) => {
      state.selectedAgreement = action.payload;
      state.selectedAgreementId = action.payload?.id;
      state.updateRecord = null;
      state.formState = FormStates.unChanged;
      state.saveState = SaveStates.idle;
      state.saveResponseAgreement = null;
      state.newAgreement = false;
      state.periodListForDelete = null;
      // state.selectedAgreementPartIndex = -1;
      // state.selectedAgreementServicesCount = 0;
      // state.selectedAgreementServiceIndex = -1;
      // state.selectedAgreementPeriodIndex = -1;
      // state.selectedAgreementPeriodsCount = 0;
      // state.selectedAgreementPriceIndex = -1;
      // state.selectedAgreementPricesCount = 0;
    },
    deleteAgreement: (state, action) => {
      const agreementIdToRemove = action.payload;
      const agreementToRemove = state.agreementList?.find(
        (item) => item.id === agreementIdToRemove
      );
      if (agreementToRemove) {
        state.agreementList = state.agreementList.filter(
          (item) => item.id !== agreementIdToRemove
        );
      }
    },
    updateSelectedAgreement: (state, action) => {
      state.selectedAgreement = {
        ...state.selectedAgreement,
        ...action.payload,
      };
      arrangeUpdateRecord(state, 'agreement', action.payload);
      state.formState = FormStates.modified;
    },
    setSelectedAgreementParts: (state, action) => {
      const parts = action?.payload;
      if (!state.selectedAgreement.AgreementParts)
        state.selectedAgreement = {
          ...state.selectedAgreement,
          ...{ AgreementParts: [...parts] },
        };
      else state.selectedAgreement.AgreementParts = [...parts];
      // if (selectedPartIndex >= 0 && selectedPartIndex < parts.length) {
      //   state.selectedAgreementPartIndex = selectedPartIndex;
      // } else {
      //   state.selectedAgreementPartIndex = -1;
      // }
    },
    createNewAgreementPart: (state, action) => {
      const { uniqueId, part } = action.payload || {
        uniqueId: null,
        part: null,
      };
      const uniqueIdText = 'new_part_' + uniqueId;
      if (part) {
        let newPart = { ...part, id: uniqueIdText };
        giveIdsPartServices({ part: newPart, uniqueId: uniqueId });
        state.newAgreementPartDetails = newPart;
      } else {
        state.newAgreementPartDetails = {
          id: uniqueIdText,
          periodValid: false,
          concessionFeeIncluded: false,
          standardApplicable: false,
          invoiceDateAppliedForExRate: false,
        };
      }

      state.selectedAgreementPartIndex = -1;
      state.newAgreementPart = true;
    },
    saveNewAgreementPart: (state) => {
      arrangeUpdateRecord(state, 'new_part', state.newAgreementPartDetails);
      if (!state.selectedAgreement.AgreementParts)
        state.selectedAgreement.AgreementParts = [];
      state.selectedAgreement.AgreementParts.push({
        ...state.newAgreementPartDetails,
      });
      state.formState = FormStates.modified;
    },
    deleteAgreementPart: (state, action) => {
      const partIdToRemove = action.payload;
      const partToRemove = state.selectedAgreement.AgreementParts?.find(
        (item) => item.id === partIdToRemove
      );
      if (partToRemove) {
        state.selectedAgreement.AgreementParts =
          state.selectedAgreement.AgreementParts.filter(
            (item) => item.id !== partIdToRemove
          );
      }
    },
    updateSelectedAgreementPartDetails: (state, action) => {
      if (state.newAgreementPart) {
        state.newAgreementPartDetails = {
          ...state.newAgreementPartDetails,
          ...action.payload,
        };
      } else {
        const part =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ];
        arrangeUpdateRecord(state, 'part_update', action.payload, part);

        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ] = {
          ...state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ],
          ...action.payload,
        };

        state.formState = FormStates.modified;
      }
    },
    setSelectedAgreementPartIndex: (state, action) => {
      state.selectedAgreementPartIndex = action.payload ?? -1;
      state.newAgreementPart = false;
      state.periodListForDelete = null;
    },
    setSelectedAgreementServices: (state, action) => {
      const { data, partIndex } = action.payload || {};
      state.selectedAgreement.AgreementParts[partIndex].AgreementServices =
      data;
      state.selectedAgreementServicesCount = data?.length ?? 0;
          },
    createNewAgreementService: (state, action) => {
      const { uniqueId, service } = action.payload || {
        uniqueId: null,
        service: null,
      };
      const uniqueIdText = 'new_service_' + uniqueId;
      if (service) {
        let newService = { ...service, id: uniqueIdText };
        giveIdsServicePeriods({ service: newService, uniqueId: uniqueId });
        state.newAgreementServiceDetails = newService;
      } else
        state.newAgreementServiceDetails = {
          id: uniqueIdText,
          delaySurchargeRate: 0,
          commissionRate: 0,
          commissionOverridden: false,
          delaySurcharge: false,
          techLandingDiscount: 0,
          divertDiscount: 0,
        };      
      // state.selectedAgreementServiceIndex = -1;
      state.newAgreementService = true;
    },
    saveNewAgreementService: (state, action) => {
      const agreement = current(state.selectedAgreement);
      let part = agreement.AgreementParts[state.selectedAgreementPartIndex];
      // state.selectedAgreement.AgreementParts[
      //   state.selectedAgreementPartIndex
      // ];
      arrangeUpdateRecord(state, 'new_service', { ...action.payload }, part);
      if (!part.AgreementServices) {
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices = [];
        part = { ...part, AgreementServices: [] };
      }
      let services = [...part.AgreementServices];
      services.push(state.newAgreementServiceDetails);
      services = _.sortBy(services, ['service.code', 'packageDefinition']);

      state.selectedAgreement.AgreementParts[
        state.selectedAgreementPartIndex
      ].AgreementServices = [...services];

      state.formState = FormStates.modified;
    },
    deleteAgreementService: (state, action) => {
      const serviceIdToDelete = action.payload;
      const serviceToDelete = state.selectedAgreement.AgreementParts[
        state.selectedAgreementPartIndex
      ].AgreementServices?.find((item) => item.id === serviceIdToDelete);
      if (serviceToDelete) {
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices = state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices.filter((item) => item.id !== serviceIdToDelete);
      }
    },
    setSelectedAgreementServiceIndex: (state, action) => {
      const serviceIndex = action.payload ?? -1;
      const service =
        state.selectedAgreement?.AgreementParts[
          state.selectedAgreementPartIndex
        ]?.AgreementServices[serviceIndex] ?? null;
      state.selectedAgreementServiceIndex = serviceIndex;

      state.selectedAgreementPeriodsCount =
        service?.agreementPeriods?.length ?? 0;
      state.editedAgreementService = service;
      state.updateForAgreementService = {};
      state.newAgreementService = false;
      state.periodListForDelete = null;
    },
    updateEditedService: (state, action) => {
      if (state.newAgreementService) {
        state.newAgreementServiceDetails = {
          ...state.newAgreementServiceDetails,
          ...action.payload,
        };
      } else {
        state.editedAgreementService = {
          ...state.editedAgreementService,
          ...action.payload,
        };
        state.updateForAgreementService = {
          ...state.updateForAgreementService,
          ...action.payload,
        };
      }
    },
    updateSelectedAgreementServiceDetails: (state, action) => {
      if (state.newAgreementService) {
        state.newAgreementServiceDetails = {
          ...state.newAgreementServiceDetails,
          ...action.payload,
        };
      } else {
        const part = state.selectedAgreement.AgreementParts[state.selectedAgreementPartIndex];
        const service = part.AgreementServices[state.selectedAgreementServiceIndex];
          
        arrangeUpdateRecord(
          state,
          'service_update',
          action.payload,
          part,
          service
        );

        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[state.selectedAgreementServiceIndex] = {
          ...state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex],
          ...action.payload,
        };

        state.formState = FormStates.modified;
      }
    },
    createNewAgreementPeriod: (state, action) => {
      const { uniqueId, period } = action.payload || {
        uniqueId: null,
        period: null,
      };
      const uniqueIdText = 'new_period_' + uniqueId;
      if (period) {
        let newPeriod = { ...period, id: uniqueIdText };
        giveIdsPeriodPrices({ period: newPeriod, uniqueId: uniqueId });
        state.newAgreementPeriodDetails = { ...newPeriod, id: uniqueIdText };
      } else state.newAgreementPeriodDetails = { id: uniqueIdText };
      state.newAgreementPeriod = true;
      state.selectedAgreementPeriodIndex = -1;
    },
    saveNewAgreementPeriod: (state) => {
      const part =
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ];
      const service =
        state.selectedAgreement.AgreementParts[state.selectedAgreementPartIndex]
          .AgreementServices[state.selectedAgreementServiceIndex];
      arrangeUpdateRecord(
        state,
        'new_period',
        state.newAgreementPeriodDetails,
        part,
        service
      );
      if (
        !state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[state.selectedAgreementServiceIndex]
          .agreementPeriods
      )
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods = [];

      state.selectedAgreement.AgreementParts[
        state.selectedAgreementPartIndex
      ].AgreementServices[
        state.selectedAgreementServiceIndex
      ].agreementPeriods.push(state.newAgreementPeriodDetails);

      state.formState = FormStates.modified;
    },
    deleteAgreementPeriod: (state, action) => {
      const periodIdToRemove = action.payload;
      const periodToRemove = state.selectedAgreement.AgreementParts[
        state.selectedAgreementPartIndex
      ].AgreementServices[state.selectedAgreementServiceIndex].agreementPeriods
      if (periodToRemove) {
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods = state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods.filter((e) => !periodIdToRemove.includes(e.id));
        
      }
    },
    setPeriodListForDelete : (state, action) => {
      state.periodListForDelete = action.payload;
    },
    setSelectedAgreementPeriodIndex: (state, action) => {
      state.selectedAgreementPeriodIndex = action.payload ?? -1;
      state.updateForAgreementPeriod = {};
      if (
        action.payload >= 0 &&
        state.selectedAgreementPartIndex >= 0 &&
        state.selectedAgreementPartIndex <
          state.selectedAgreement.AgreementParts.length &&
        state.selectedAgreementServiceIndex >= 0 &&
        state.selectedAgreementServiceIndex <
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices.length &&
        state.selectedAgreementPeriodIndex >= 0 &&
        state.selectedAgreementPeriodIndex <
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods.length
      ) {
        state.editedAgreementPeriod =
          state.selectedAgreement?.AgreementParts[
            state.selectedAgreementPartIndex
          ]?.AgreementServices[
            state.selectedAgreementServiceIndex
          ]?.agreementPeriods[state.selectedAgreementPeriodIndex];
      }
      state.newAgreementPeriod = false;
    },
    updateSelectedAgreementPeriod: (state, action) => {
      if (state.newAgreementPeriod) {
        state.newAgreementPeriodDetails = {
          ...state.newAgreementPeriodDetails,
          ...action.payload,
        };
      } else {
        const part =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ];
        const service =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex];
        const period =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex];
        arrangeUpdateRecord(
          state,
          'period_update',
          action.payload,
          part,
          service,
          period
        );
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods[state.selectedAgreementPeriodIndex] = {
          ...state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex],
          ...action.payload,
        };
        state.formState = FormStates.modified;
      }
    },
    createNewAgreementPrice: (state, action) => {
      const { uniqueId, price } = action.payload || {
        uniqueId: null,
        price: null,
      };
      const uniqueIdText = 'new_price_' + uniqueId;

      if (price) {
        state.newAgreementPriceDetails = { ...price, id: uniqueIdText };
      } else {
        state.newAgreementPriceDetails = {
          ...price,
          id: uniqueIdText,
          parentTag: price?.parentTag,
          tag: price?.tag,
        };
      }

      state.newAgreementPrice = true;
      state.selectedAgreementPriceIndex = -1;
      state.selectedAgreementPricesCount = 0;
    },
    saveNewAgreementPrice: (state, action) => {
      const { newPrice, partIndex, serviceIndex, periodIndex } =
        action.payload || {};
      const part = state.selectedAgreement.AgreementParts[partIndex];
      const service =
        state.selectedAgreement.AgreementParts[partIndex].AgreementServices[
          serviceIndex
        ];
      const period =
        state.selectedAgreement.AgreementParts[partIndex].AgreementServices[
          serviceIndex
        ].agreementPeriods[periodIndex];

      arrangeUpdateRecord(state, 'new_price', newPrice, part, service, period);
      if (
        !state.selectedAgreement.AgreementParts[partIndex].AgreementServices[
          serviceIndex
        ].agreementPeriods[periodIndex].pricingConditions
      ) {
        state.selectedAgreement.AgreementParts[partIndex].AgreementServices[
          serviceIndex
        ].agreementPeriods[periodIndex].pricingConditions = [];
      }

      state.selectedAgreement.AgreementParts[partIndex].AgreementServices[
        serviceIndex
      ].agreementPeriods[periodIndex].pricingConditions.push({ ...newPrice });

      state.formState = FormStates.modified;
    },
    deleteAgreementPrice: (state, action) => {
      const priceIdToRemove = action.payload;
      const priceToRemove = state.selectedAgreement.AgreementParts[
        state.selectedAgreementPartIndex
      ].AgreementServices[state.selectedAgreementServiceIndex].agreementPeriods[
        state.selectedAgreementPeriodIndex
      ].pricingConditions
      if (priceToRemove) {
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods[
          state.selectedAgreementPeriodIndex
        ].pricingConditions = state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods[
          state.selectedAgreementPeriodIndex
        ].pricingConditions.filter((e) =>!priceIdToRemove.includes(e.id));
      }
    },

    setSelectedAgreementPriceIndex: (state, action) => {
      state.selectedAgreementPriceIndex = _.findIndex(
        state.selectedAgreement.AgreementParts[state.selectedAgreementPartIndex]
          .AgreementServices[state.selectedAgreementServiceIndex]
          .agreementPeriods[state.selectedAgreementPeriodIndex]
          .pricingConditions,
        { id: action.payload?.id }
      );
      state.newAgreementPrice = false;
    },
    // setSelectedAgreementPrice: (state, action) => {

    //   state.selectedAgreementPriceIndex = _.findIndex(
    //     state.selectedAgreement.AgreementParts[state.selectedAgreementPartIndex].AgreementServices[
    //       state.selectedAgreementServiceIndex
    //     ].agreementPeriods[state.selectedAgreementPeriodIndex].pricingConditions,
    //     { id: action.payload?.id }
    //   );
    //   state.newAgreementPrice = false;
    // },

    updateAgreementPriceById: (state, action) => {
      const { priceId, record } = action.payload || {};
      const priceIndex = state.selectedAgreement.AgreementParts[
        state.selectedAgreementPartIndex
      ].AgreementServices[state.selectedAgreementServiceIndex].agreementPeriods[
        state.selectedAgreementPeriodIndex
      ].pricingConditions.findIndex((e) => e.id === priceId);
      if (priceIndex >= 0) {
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods[
          state.selectedAgreementPeriodIndex
        ].pricingConditions[priceIndex] = {
          ...state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex]
            .pricingConditions[priceIndex],
          ...record,
        };
        const part =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ];
        const service =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex];
        const period =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex];
        const price =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex]
            .pricingConditions[priceIndex];

        arrangeUpdateRecord(
          state,
          'price_update',
          record,
          part,
          service,
          period,
          price
        );
        state.formState = FormStates.modified;
      }
    },
    updateEditedPeriod: (state, action) => {
      if (state.newAgreementPeriod) {
        state.newAgreementPeriodDetails = {
          ...state.newAgreementPeriodDetails,
          ...action.payload,
        };
      } else {
        state.editedAgreementPeriod = {
          ...state.editedAgreementPeriod,
          ...action.payload,
        };
        state.updateForAgreementPeriod = {
          ...state.updateForAgreementPeriod,
          ...action.payload,
        };
      }
    },
    updateSelectedAgreementPrice: (state, action) => {
      if (state.newAgreementPrice) {
        state.newAgreementPriceDetails = {
          ...state.newAgreementPriceDetails,
          ...action.payload,
        };
      } else {
        const part =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ];
        const service =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex];
        const period =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex];
        const price =
          state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex]
            .pricingConditions[state.selectedAgreementPriceIndex];
        arrangeUpdateRecord(
          state,
          'price_update',
          action.payload,
          part,
          service,
          period,
          price
        );
        state.selectedAgreement.AgreementParts[
          state.selectedAgreementPartIndex
        ].AgreementServices[
          state.selectedAgreementServiceIndex
        ].agreementPeriods[
          state.selectedAgreementPeriodIndex
        ].pricingConditions[state.selectedAgreementPriceIndex] = {
          ...state.selectedAgreement.AgreementParts[
            state.selectedAgreementPartIndex
          ].AgreementServices[state.selectedAgreementServiceIndex]
            .agreementPeriods[state.selectedAgreementPeriodIndex]
            .pricingConditions[state.selectedAgreementPriceIndex],
          ...action.payload,
        };
        state.formState = FormStates.modified;
      }
    },
    setLastSavedRecord: (state, action) => {
      state.lastSavedRecord = action.payload;
    },
    setSaveResponseAgreement: (state, action) => {
      state.saveResponseAgreement = action.payload;
    },
    setFormState: (state, action) => {
      state.formState = action.payload;
    },
    setSaveState: (state, action) => {
      state.saveState = action.payload;
    },
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
    setSelectedAgreementLoading: (state, action) => {
      state.selectedAgreementLoading = action.payload;
    },
    setSelectedAgreementPartsLoading: (state, action) => {
      state.selectedAgreementPartsLoading = action.payload;
    },
    setSelectedAgreementPartDetailsLoading: (state, action) => {
      state.selectedAgreementPartDetailsLoading = action.payload;
    },
    setSelectedAgreementServicesLoading: (state, action) => {
      state.selectedAgreementServicesLoading = action.payload;
    },
    setSelectedAgreementServiceDetailsLoading: (state, action) => {
      state.selectedAgreementServiceDetailsLoading = action.payload;
    },
    setClearCustomerAgreementSlice: (state, action) => {
      return initialState;
    },
    setClearUpdateRecord: (state, action) => {
      state.updateRecord = null;
    },
    ClearSelections: (state, action) => {
      const level = action?.payload ?? null;
      if (level === 'agreement') {
        state.selectedAgreement = null;
        state.selectedAgreementId = null;
      }
      if (level === 'part' || level === 'agreement') {
        state.selectedAgreementPartIndex = -1;
        state.selectedAgreementServicesCount = 0;
      }
      if (level === 'service' || level === 'part' || level === 'agreement') {
        state.selectedAgreementServiceIndex = -1;
        state.selectedAgreementPeriodsCount = 0;
      }
      if (
        level === 'period' ||
        level === 'service' ||
        level === 'part' ||
        level === 'agreement'
      ) {
        state.selectedAgreementPeriodIndex = -1;
        state.selectedAgreementPricesCount = 0;
      }
      if (
        level === 'price' ||
        level === 'period' ||
        level === 'service' ||
        level === 'part' ||
        level === 'agreement'
      ) {
        state.selectedAgreementPriceIndex = -1;
      }
      if (
        level !== 'agreement' &&
        level !== 'part' &&
        level !== 'service' &&
        level !== 'period' &&
        level !== 'price'
      ) {
        throw new Error('Invalid Clear Selections Level');
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  createNewAgreement,
  setAgreementList,
  setSelectedAgreement,
  deleteAgreement,
  updateSelectedAgreement,

  setSelectedAgreementParts,
  createNewAgreementPart,
  deleteAgreementPart,
  saveNewAgreementPart,
  setSelectedAgreementPartIndex,
  updateSelectedAgreementPartDetails,

  setSelectedAgreementServices,
  createNewAgreementService,
  saveNewAgreementService,
  setSelectedAgreementServiceIndex,
  updateSelectedAgreementServiceDetails,
  updateEditedService,
  createNewAgreementPeriod,
  saveNewAgreementPeriod,
  setSelectedAgreementPeriodIndex,
  deleteAgreementPeriod,
  updateSelectedAgreementPeriod,
  updateEditedPeriod,
  setPeriodListForDelete,
  createNewAgreementPrice,
  saveNewAgreementPrice,
  deleteAgreementPrice,
  setSelectedAgreementPriceIndex,
  setSelectedAgreementPrice, // OLD Function keep it for now
  deleteAgreementService,
  updateSelectedAgreementPrice,
  updateAgreementPriceById,
  setLastSavedRecord,
  setSaveResponseAgreement,
  setFormState,
  setSaveState,
  setGlobalLoading,

  setSelectedAgreementLoading,
  setSelectedAgreementPartsLoading,
  setSelectedAgreementPartDetailLoading,
  setSelectedAgreementServicesLoading,
  setSelectedAgreementServiceDetailsLoading,

  setClearCustomerAgreementSlice,
  setClearUpdateRecord,
  ClearSelections,
} = selectedAgreementSlice.actions;

export default selectedAgreementSlice.reducer;




const giveIdsAgreementParts = ({ agreement, uniqueId }) => {
  let newParts = [];
  let nextUniqueId = uniqueId + 1;
  if (agreement.AgreementParts) {
    for (let i = 0; i < agreement.AgreementParts.length; i++) {
      let part = { ...agreement.AgreementParts[i], id: 'new_part_' + nextUniqueId++ };
      giveIdsPartServices({ part, uniqueId: nextUniqueId });
      newParts.push(part);
    }
    agreement.AgreementParts = newParts;
  }
};

const giveIdsPartServices = ({ part, uniqueId }) => {
  let newServices = [];
  let nextUniqueId = uniqueId + 1;
  if (part.AgreementServices) {
    for (let i = 0; i < part.AgreementServices.length; i++) {
      let service = { ...part.AgreementServices[i], id: 'new_service_' + nextUniqueId };
      giveIdsServicePeriods({ service, uniqueId: nextUniqueId });
      nextUniqueId++;
      newServices.push(service);
    }
    part.AgreementServices = newServices;
  }
};

const giveIdsServicePeriods = ({ service, uniqueId }) => {
  let newPeriods = [];
  let nextUniqueId = uniqueId + 1;
  if (service?.agreementPeriods) {
    for (let i = 0; i < service.agreementPeriods.length; i++) {
      let period = { ...service.agreementPeriods[i], id: 'new_period_' + nextUniqueId++ };
      giveIdsPeriodPrices({ period, uniqueId: nextUniqueId });
      newPeriods.push(period);
    }
    service.agreementPeriods = newPeriods;
  }
};

const giveIdsPeriodPrices = ({ period, uniqueId }) => {
  let newPrices = [];
  let nextUniqueId = uniqueId + 1;
  if (period?.pricingConditions) {
    for (let i = 0; i < period.pricingConditions.length; i++) {
      newPrices.push({ ...period.pricingConditions[i], id: 'new_price_' + nextUniqueId++ });
    }
    period.pricingConditions = newPrices;
  }
};
