import React, { useEffect, useState, useMemo } from 'react';
import useLocale from '../../../hooks/useLocale'
import { useDispatch, useSelector } from 'react-redux';

import { InvoiceAPI, ConcessionFeeAPI } from '../../../services';
import toast from "react-hot-toast";
import {setGlobalLoading} from "../../../contexts/mainSlice";
import {
    setFFResultset,
    setFFToggleEditKey,
    setFFToggleDuplicateKey,
    setFFOrder, setFormulaOrder, setCeilingRatesResultset
} from '../../../contexts/concessionfee/concessionFeeSlice';
import {Alert, Paper, TableCell, TableContainer, TableRow, TableHead, Table, TableBody} from "@mui/material";
import { TableHeadCell } from "../../../components/Table/TableHeadCell";
import FFForm from "../FFForm";
import Iconify from "../../../components/Iconify";
import IconButton from "../../../components/IconButton";
import secureLocalStorage from "react-secure-storage";
import ConfirmDialog from '../../../components/ConfirmDialog';
import usePermissions from "../../../hooks/usePermissions";
import Typography from "@mui/material/Typography";
import PageContainer from "../../../components/PageContainer";
import CRForm from "../../CeilingRates/CRForm";


export default function FFTable() {
    //const { themeSize } = useSettings();
    const dispatch = useDispatch();

    {/* Translate */ }
    const { t } = useLocale();
    const translate = (value) => {
        let _t = `ConcessionFee.FlightFactor.index.${value}`;
        return t(_t);
    };
    const { hasPermission } = usePermissions({ pageName: 'FlightFactorTable' });
    const data = useSelector((state)=> state.ConcessionFee.FlightFactor.Data)
    const loadType = useSelector((state)=> state.ConcessionFee.LoadType)
    const flightFactorData = useSelector((state)=> state.ConcessionFee.FlightFactorData)
    const toggleEditKey = useSelector((state)=> state.ConcessionFee.FlightFactor.toggleEditKey)
    const toggleDuplicateKey = useSelector((state)=> state.ConcessionFee.FlightFactor.toggleDuplicateKey)
    const [airPortData, setAirPortData] = useState([])
    const UserInfo = JSON.parse(secureLocalStorage.getItem('user'))
    const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const tableOrder = useSelector((state)=>state.ConcessionFee.FlightFactor.tableOrder);
    const [originsort,setOriginSort] = useState([]);

    const sortHandler=(order, orderobj)=>{
        dispatch(setFFOrder(orderobj));
        if(order) {
            setSortData(data, orderobj);
        }else{
            dispatch(setFFResultset(originsort));
        }
    }
    const setSortData=(data, tableorder)=>{
        let sortingdata = [];
        data.map((item, key)=>{
            sortingdata[key] = {}
            Object.keys(item).map((col, ck)=>{
                switch(col){
                    case "airportId":
                        sortingdata[key][col] = getLabelById(airPortData, item.airportId);
                        break;
                    case "flightFactor":
                        sortingdata[key][col] = getLabelById(flightFactorData, item.flightFactor);
                        break;
                    case "loadType":
                        sortingdata[key][col] = getLabelById(loadType, item.loadType);
                        break;
                    default:
                        sortingdata[key][col] = item[col];
                        break;
                }
            });
        });
        let orderdata = null;
        Object.keys(tableorder).map((ti, tk)=>{
            orderdata = tableorder[ti];
        });

        if(orderdata) {
            sortingdata.sort((n1, n2) => {
                if(typeof n1[orderdata[0]] == "string"){
                    return n1[orderdata[0]].localeCompare(n2[orderdata[0]]) * (orderdata[1]=="desc"?-1:1);
                }else{
                    switch (orderdata[1]) {
                        case 'asc':
                            return n1[orderdata[0]] > n2[orderdata[0]] ? 1 : -1;
                            break;
                        case 'desc':
                            return n1[orderdata[0]] > n2[orderdata[0]] ? -1 : 1;
                            break;
                    }
                }
            });
        }

        let sorteddata = [];
        sortingdata.map((item, key)=>{
            data.map((d, k)=>{ if(d.id == item.id) sorteddata.push(d); })
        });
        dispatch(setFFResultset(sorteddata));
    }
    const searchInTable=(params)=>{
        let searcheddata = originsort;
        Object.keys(params).map((e,i)=>{
            let colname=e;
            switch(e){
                case "airport":
                    colname = "airportId";
                    break;
            }
            searcheddata = searchData(colname,params[e],searcheddata);
        });
        setSortData(searcheddata, tableOrder);
        dispatch(setCeilingRatesResultset(searcheddata));
    }
    const searchData = (colname,colvalue,searcheddata)=>{
        if(colvalue == null || ((typeof colvalue?.select == "object" || typeof colvalue?.select == "array") && !colvalue?.select?.id)){
            return searcheddata;
        }
        let newdata = searcheddata.filter((item)=>{
            if((typeof colvalue?.select == "object" || typeof colvalue?.select == "array") && colvalue?.select?.id) {
                if(item[colname]?.id == colvalue?.select?.id){
                    return true;
                }
                if(item[colname] == colvalue?.select?.id){
                    return true;
                }
            }else{
                let expression = `.*${colvalue}.*`;
                let re = new RegExp(expression, 'gi');
                if(re.test(item[colname])) {
                    return true;
                }
            }
            return false;
        });
        return newdata;
    }

    const searchFF = async(order) =>{
        dispatch(setGlobalLoading({open:true, info:t(`components.GlobalSpinner.Processing`)+'...'}));
        ConcessionFeeAPI.getFFTable().then(res=>{
            if (res.error) {
                toast.error(res.error)
                return
            }
            dispatch(setFFResultset(res.data));
            setOriginSort(res.data);
            if(order) {
                setSortData(res.data, order);
            }else{
                setSortData(res.data, tableOrder);
            }
            dispatch(setGlobalLoading({open:false, info:''}));
        });
    }

    useEffect(()=>{
        InvoiceAPI.operatedAirPorts(UserInfo.branch.code)
            .then(res => {
                if (res.error) toast.error(res.error)
                var array = []
                res.data.map(item => {
                    const list = { label: item?.iataCode, id: item?.id, description: item?.iataCode }
                    array.push(list)
                })
                setAirPortData(array)
            })
        searchFF();

    },[]);

    const getItemById = (val, id)=>{
        let item = null;
        val.map((e,i)=>{
            if(e.id == id) item = e;
        });
        return item;
    }

    const getLabelById = (val, id)=>{
        let typename = '';
        val.map((e,i)=>{
            if(e.id == id) typename = e.label;
        });
        return typename;
    }

    const editItem = (key) =>{
        dispatch(setFFToggleEditKey(key));
    }
    const duplicateItem = (key) =>{
        dispatch(setFFToggleDuplicateKey(key));
    }
    const deleteItem = ()=>{
        setConfirmCancelVisible(false);
        ConcessionFeeAPI.deleteFF(deleteId).then(res=>{
            if (res.error) {
                toast.error(res.error)
                return
            }
            searchFF();
        });
    }

    const renderCancelConfirmDialog = useMemo(() => {
        return (
            confirmCancelVisible && (
                <ConfirmDialog
                    visible={confirmCancelVisible}
                    setVisible={setConfirmCancelVisible}
                    onClick={deleteItem}
                    title={t('components.ConfirmDialog.index.Confirm Transaction')}
                >
                    <Typography>{t('components.ConfirmDialog.index.Are you sure to delete Flight Factor data?')}</Typography>
                </ConfirmDialog>
            )
        );
    }, [confirmCancelVisible, deleteItem, t]);

    const headStyle = {
        "& > th, td": {
            ".MuiButtonBase-root.MuiTableSortLabel-root.Mui-active": {color: "#ffffff"},
            ".MuiButtonBase-root.MuiTableSortLabel-root":{
                "&:hover, &:focus":{ color: "rgba(255, 255, 255, 0.8)" }
            }
        }
    };


    return (
        <PageContainer>
                <TableContainer component={Paper} style={{overflow:"visible"}}>
                    <Table>
                        <TableHead>
                            <FFForm action="Search" complete={searchInTable}></FFForm>
                        </TableHead>
                        <TableHead>
                            <TableRow key={'head'} sx={headStyle}>
                                <TableHeadCell tableOrder={tableOrder} columnKey={'airportId'} onSortAction={(o,ob)=>{ sortHandler(o,ob) }}>{translate('Station')}</TableHeadCell>
                                <TableHeadCell tableOrder={tableOrder} columnKey={'flightFactor'} onSortAction={(o,ob)=>{ sortHandler(o,ob) }}>{translate('Flight Factor')}</TableHeadCell>
                                <TableHeadCell tableOrder={tableOrder} columnKey={'loadType'} onSortAction={(o,ob)=>{ sortHandler(o,ob) }}>{translate('Load Type')}</TableHeadCell>
                                <TableHeadCell tableOrder={tableOrder} columnKey={'value'} onSortAction={(o,ob)=>{ sortHandler(o,ob) }}>{translate('Value')}</TableHeadCell>
                                <TableCell><div style={{width:"5rem"}}></div></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasPermission('Add')&&<FFForm action="Add" complete={()=>{ dispatch(setFFOrder({id:["id","desc"]})); searchFF({id:["id","desc"]}) }} />}
                            {data && data.length == 0 ? <TableCell colspan={'100%'}><Alert severity="info">{t(`ConcessionFee.index.There were no results.`)}</Alert></TableCell> : (
                                <>
                                {data && data.map((item, key) => (
                                    <>
                                        {toggleEditKey!==key&&<TableRow key={key} >
                                            <TableCell>{getLabelById(airPortData, item.airportId)}</TableCell>
                                            <TableCell>{getLabelById(flightFactorData, item.flightFactor)}</TableCell>
                                            <TableCell>{getLabelById(loadType, item.loadType)}</TableCell>
                                            <TableCell>{item.value}</TableCell>
                                            <TableCell>
                                                {(!toggleDuplicateKey||toggleDuplicateKey!=key)&&(
                                                    <div style={{width:"7.5rem"}}>
                                                        {hasPermission('Update')&&<IconButton onClick={()=>{editItem(key)}} title={t("ConcessionFee.index.Edit")}><Iconify icon="eva:edit-fill" /></IconButton>}
                                                        {hasPermission('Update')&&<IconButton onClick={()=>{duplicateItem(key)}} title={t("ConcessionFee.index.Duplicate")}><Iconify icon="eva:copy-fill" /></IconButton>}
                                                        {hasPermission('Delete')&&<IconButton onClick={()=>{setDeleteId(item.id); setConfirmCancelVisible(true);}} title={t("ConcessionFee.index.Delete")}><Iconify icon="eva:trash-2-outline" /></IconButton>}
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>}
                                        {toggleEditKey === key && <FFForm action="Edit" item={item} complete={searchFF}></FFForm>}
                                        {toggleDuplicateKey == key && <FFForm action="Duplicate" item={item} complete={searchFF}></FFForm>}

                                    </>
                                ))}
                                </>
                            )}
                        </TableBody>
                    </Table>

                </TableContainer>
            {renderCancelConfirmDialog}
        </PageContainer>
    );
}